<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="プロフィール" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">アカウント情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>氏名</label>
              </div>
              <div class="c-inputWrap__items">
                {{ $data.profile.name }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>メールアドレス</label>
              </div>
              <div class="c-inputWrap__items">
                {{ $data.profile.mail }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>電話番号</label>
              </div>
              <div class="c-inputWrap__items">
                {{ $data.profile.tel }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>携帯電話番号</label>
              </div>
              <div class="c-inputWrap__items">
                {{ $data.profile.mobilePhone }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>所属先</label>
              </div>
              <div v-if="$data.profile.groupInfo" class="c-inputWrap__items">
                {{ $data.profile.groupInfo.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="l-contents l-box" v-if="useAuth=='false'">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">ログイン情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>現在のパスワード</label>
              </div>
              <div class="c-inputWrap__items">
                <input
                  class="text-psw"
                  type="password"
                  v-model="password"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="l-btns l-container">
        <div class="left">
          <div class="c-btn secondary small back" @click="backHandler">戻る</div>
        </div>
        <div class="center">
          <router-link to="/profile/edit" class="c-btn secondary small">
            編集
          </router-link>
          <div @click="gotoPasswordChange" class="c-btn secondary small" v-if="useAuth!='false'">
            パスワード変更
          </div>
        </div>
      </div>
    </main>
    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import {API_URL} from "@/const";
axios.defaults.headers.common["Content-Type"] = "application/json";

export default {
  name: "profile",
  components: {},
  data() {
    return {
      profile: {},
      useAuth: process.env.VUE_APP_USE_AUTH,
      password: "password01",
    };
  },
  mounted() {
    // profile取得のAPI
    axios
      .get(API_URL.PROFILE.PROFILE)
      .then((res) => {
        this.$data.profile = res.data;
      })
      .catch(() => {});
  },
  methods: {
    backHandler() {
      this.$router.go(-1);
    },
    gotoPasswordChange() {
      window.location.href = `${process.env.VUE_APP_AUTH_URL}/password-change?redirect_uri=` + encodeURI(window.location.href);
    },
  }
};
</script>

<style scoped>
.text-psw {
  border: none;
  background-color: transparent;
}
.title-wrap {
  display: flex;
  justify-content: space-between;
}
</style>
